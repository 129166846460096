<template>
  <div class="center">
    <router-view />
  </div>
</template>

<style>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  scrollbar-width: none;
}

.center img {
  width: 100%;
}
</style>
