<!-- eslint-disable space-before-function-paren -->
<!-- eslint-disable no-useless-return -->
<!-- eslint-disable eqeqeq -->
<!-- eslint-disable no-debugger -->
<template>
    <div class="center">
        <img alt="Vue logo" src="../assets/rego.png">
    </div>
</template>

<script setup>
import axios from 'axios'
redirectToIosStore()
function redirectToIosStore () {
  console.log('API call started')
  axios.post('https://apimob.regoride.com/api/version/get_version')
    .then(response => {
      console.log('API call success:', response)
      if (response.status === 200 && response.data.status === 200) {
        window.location.href = response.data.data.appStoreUrl
      }
      console.error('API call failed')
    })
    .catch(error => console.error('API call failed:', error))
}
</script>
<style>
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    scrollbar-width: none;
}

.center img {
    width: 100%;
}
</style>
