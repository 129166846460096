import { createRouter, createWebHashHistory } from 'vue-router'
import home from '../views/home_view.vue'
import android from '../views/android_view.vue'
import ios from '../views/ios_view.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/android',
    name: 'android',
    component: android
  },
  {
    path: '/ios',
    name: 'ios',
    component: ios
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
